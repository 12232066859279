import { Big } from 'big.js'
import type { FormattedOptionAccount } from '@base/types/options'
import { useWallet } from '@base/composables/useWallet'
import {
  BASE_TOKEN_SYMBOL,
  type BasicTokenType,
  type BulletType,
  type SniperType,
} from '@deorderbook/shared'

const TOKEN_SYMBOLS: Exclude<BasicTokenType, 'ETH'>[] = [
  BASE_TOKEN_SYMBOL.SELL_TOKEN,
  BASE_TOKEN_SYMBOL.USDC,
  BASE_TOKEN_SYMBOL.DOB,
  BASE_TOKEN_SYMBOL.SELL_TOKEN_HODL,
  BASE_TOKEN_SYMBOL.U_HODL,
] as const

export interface BulletAsset {
  type: 'BULLET'
  token: BulletType
  nickName: string
  optionAddress: string
  bullet: string
  matchingBulletAmount: string
  strikePrice: string
  price: string
  exerciseTimestamp: string
  amount: string
  value: string
  startTimestamp: string
}

export interface SniperAsset {
  type: 'SNIPER'
  id: string
  optionAddress: string
  token: SniperType
  nickName: string
  sniper: string
  stakedAmount: string
  matchingSniperAmount: string
  matchingAllSniperAmount: string
  bullet: string
  dobAmount: string
  rewards: string
  amount: string
  strikePrice: string
  exerciseTimestamp: string
  value: string
  startTimestamp: string
  expiryTimestamp: string
  sniperData: FormattedOptionAccount
}

export interface TokenAsset {
  type: 'TOKEN'
  token: BasicTokenType
  balance: string
  amount: string
  price: string
  value: string
}

export type AssetItem = BulletAsset | SniperAsset | TokenAsset

const getTokenBalanceFromWallet = (
  wallet: ReturnType<typeof useWallet>,
  token: string,
) => {
  switch (token) {
    case BASE_TOKEN_SYMBOL.SELL_TOKEN:
      return wallet.balanceSellToken
    case BASE_TOKEN_SYMBOL.USDC:
      return wallet.balanceUSDC
    case BASE_TOKEN_SYMBOL.DOB:
      return wallet.balanceDOB
    case BASE_TOKEN_SYMBOL.SELL_TOKEN_HODL:
      return wallet.balanceSellTokenHODL
    case BASE_TOKEN_SYMBOL.U_HODL:
      return wallet.balanceUHODL
    default:
      return ref('0')
  }
}
const getSniperBalances = (
  sniperList: Ref<FormattedOptionAccount[]>,
  divDecimals: ReturnType<typeof useTokens>['divDecimals'],
) => {
  return sniperList.value.map((item) => {
    const value = item.matchingSniperAmountUSD
    const token = item.type

    return {
      type: 'SNIPER',
      id: item.id,
      optionAddress: item.address,
      token,
      nickName: item.name,
      sniper: item.sniper,
      stakedAmount: item.stakedAmount,
      matchingSniperAmount: item.matchingSniperAmount,
      matchingAllSniperAmount: item.matchingAllSniperAmount,
      bullet: item.bullet,
      dobAmount: divDecimals(item.claimableDOBAmount, BASE_TOKEN_SYMBOL.DOB)
        .value,
      rewards: useUSDFormat(item.claimableDOBAmountUSD),
      amount: useTokenNumberFormat(
        divDecimals(item.matchingSniperAmount, 'sniper').value,
        { token },
      ),
      strikePrice: item.strikePrice,
      exerciseTimestamp: item.exerciseTimestamp,
      value: useUSDFormat(value),
      startTimestamp: item.startTimestamp,
      expiryTimestamp: item.expiryTimestamp,
      sniperData: item,
    } satisfies SniperAsset
  })
}
const getBulletBalances = (
  bullets: BulletsType,
  divDecimals: ReturnType<typeof useTokens>['divDecimals'],
) => {
  return [...bullets.sellTokenBULLET, ...bullets.uBULLET].map((item) => {
    const token = item.token

    return {
      type: 'BULLET',
      token,
      nickName: item.nickName,
      optionAddress: item.optionAddress,
      bullet: item.bullet,
      matchingBulletAmount: item.amount,
      strikePrice: item.strikePrice,
      price: item.price,
      exerciseTimestamp: item.exerciseTimestamp,
      amount: useTokenNumberFormat(divDecimals(item.amount, 'bullet').value, {
        token,
      }),
      value: item.value,
      startTimestamp: item.startTimestamp,
    } satisfies BulletAsset
  })
}
const getTokenBalances = (
  wallet: ReturnType<typeof useWallet>,
  getTokenPrice: ReturnType<typeof useTokens>['getTokenPrice'],
  divDecimals: ReturnType<typeof useTokens>['divDecimals'],
) => {
  return TOKEN_SYMBOLS.map((token) => {
    const tokenPrice = getTokenPrice(token).value
    const balance = getTokenBalanceFromWallet(wallet, token).value
    const amount = divDecimals(Number(balance) < 0 ? 0 : balance, token).value
    return {
      type: 'TOKEN',
      token,
      balance,
      amount: useTokenNumberFormat(amount, { token }),
      price: `${useUSDFormat(tokenPrice)}`,
      value: `${useUSDFormat(Big(amount).times(tokenPrice))}`,
    } satisfies TokenAsset
  })
}

/**
 * @description  Fetches and computes the user's assets across various categories including tokens, snipers, bullets, and HODL pools.
 */
export function useUserAssets() {
  const wallet = useWallet()
  const { getTokenPrice, divDecimals } = useTokens()

  const { formattedUserSnipers, loading: isOptionAccountsLoading } = toRefs(
    useOptionAccountsStore(),
  )
  const { userHODLPools, loading: isHodlPoolsLoading } = toRefs(useHODLStore())
  const { userInfo } = toRefs(useDOBStore())

  const {
    sniperType: sniperFilter,
    isLoading: isSniperLoading,
    sniperData: sniperList,
  } = useSniper()

  sniperFilter.value = SNIPER_TYPE.WALLET

  const { bullets, isLoading: isBulletsLoading } = useBullet({
    activeOnly: true,
    address: wallet.address.value,
  })

  const loading = computed(() => {
    if (!wallet.isConnected.value) return false
    return (
      isSniperLoading.value ||
      isBulletsLoading.value ||
      isHodlPoolsLoading.value ||
      isOptionAccountsLoading.value
    )
  })

  const sniperBalances = computed(() => {
    return getSniperBalances(sniperList, divDecimals)
  })

  const bulletBalances = computed(() => {
    if (!wallet.isConnected.value) return []
    return getBulletBalances(bullets, divDecimals)
  })

  const tokenBalances = computed(() => {
    if (!wallet.isConnected.value) return []
    return getTokenBalances(wallet, getTokenPrice, divDecimals)
  })

  const data = computed(() => {
    if (!wallet.isConnected.value) return []
    return [
      ...tokenBalances.value,
      ...sniperBalances.value,
      ...bulletBalances.value,
    ] as AssetItem[]
  })

  // [ Token Total ]
  const totalSniperTokenAmount = computed(() => {
    // add staked sniper amount (USD)
    let uSNIPERTotal = Big(0)
    let sellTokenSNIPERTotal = Big(0)
    formattedUserSnipers.value.forEach((sniper) => {
      if (sniper.type === BASE_TOKEN_SYMBOL.U_SNIPER) {
        uSNIPERTotal = uSNIPERTotal.add(sniper.stakedAmount)
        uSNIPERTotal = uSNIPERTotal.add(sniper.matchingBulletAmount)
        uSNIPERTotal = uSNIPERTotal.add(sniper.matchingSniperAmount)
      } else {
        sellTokenSNIPERTotal = sellTokenSNIPERTotal.add(sniper.stakedAmount)
        sellTokenSNIPERTotal = sellTokenSNIPERTotal.add(
          sniper.matchingBulletAmount,
        )
        sellTokenSNIPERTotal = sellTokenSNIPERTotal.add(
          sniper.matchingSniperAmount,
        )
      }
    })

    return {
      total: uSNIPERTotal.add(sellTokenSNIPERTotal).toFixed(),
      uSNIPERTotal: uSNIPERTotal.toFixed(),
      sellTokenSNIPERTotal: sellTokenSNIPERTotal.toFixed(),
    }
  })
  const totalSniperUSD = computed(() => {
    // add staked sniper amount (USD)
    let uSNIPERTotal = Big(0)
    let uSNIPERAmount = Big(0)
    let sellTokenSNIPERTotal = Big(0)
    let sellTokenSNIPERAmount = Big(0)
    formattedUserSnipers.value.forEach((sniper) => {
      if (sniper.type === BASE_TOKEN_SYMBOL.U_SNIPER) {
        uSNIPERTotal = uSNIPERTotal.add(sniper.stakedAmountUSD)
        uSNIPERAmount = uSNIPERAmount.add(sniper.stakedAmount)
        uSNIPERTotal = uSNIPERTotal.add(sniper.matchingBulletAmountUSD)
        uSNIPERTotal = uSNIPERTotal.add(sniper.matchingSniperAmountUSD)
      } else {
        sellTokenSNIPERTotal = sellTokenSNIPERTotal.add(sniper.stakedAmountUSD)
        sellTokenSNIPERAmount = sellTokenSNIPERAmount.add(sniper.stakedAmount)
        sellTokenSNIPERTotal = sellTokenSNIPERTotal.add(
          sniper.matchingBulletAmountUSD,
        )
        sellTokenSNIPERTotal = sellTokenSNIPERTotal.add(
          sniper.matchingSniperAmountUSD,
        )
      }
    })

    return {
      total: uSNIPERTotal.add(sellTokenSNIPERTotal).toFixed(),
      /** all staked sniper amount */
      uSNIPERAmount: uSNIPERAmount.toFixed(),
      uSNIPERTotal: uSNIPERTotal.toFixed(),
      sellTokenSNIPERTotal: sellTokenSNIPERTotal.toFixed(),
      sellTokenSNIPERAmount: sellTokenSNIPERAmount.toFixed(),
    }
  })
  const totalDOBUSD = computed(() => {
    let total = Big(0)

    // balance
    const dobBalance = getTokenBalanceFromWallet(wallet, BASE_TOKEN_SYMBOL.DOB)
    const dobPrice = getTokenPrice(BASE_TOKEN_SYMBOL.DOB).value
    const dobUSD = Big(
      divDecimals(dobBalance ?? '0', BASE_TOKEN_SYMBOL.DOB).value,
    ).times(dobPrice)

    // dob rewards generated by stack snipers that have not been claimed yet
    const totalClaimableDOBAmountUSD = formattedUserSnipers.value.reduce(
      (sum, current) => {
        return sum.add(current.claimableDOBAmountUSD ?? '0')
      },
      Big(0),
    )

    // add staked DOB
    const stakedDOB = total.add(
      Big(
        divDecimals(
          userInfo.value.totalStakingAmount ?? '0',
          BASE_TOKEN_SYMBOL.DOB,
        ).value,
      ).mul(dobPrice),
    )

    // add HODL pool reward
    const hodlPoolReward = userHODLPools.value.reduce((sum, current) => {
      return sum.add(
        Big(
          divDecimals(current.rewardDOBAmount, BASE_TOKEN_SYMBOL.DOB).value,
        ).mul(dobPrice ?? '0'),
      )
    }, Big(0))

    total = total
      .add(dobUSD)
      .add(totalClaimableDOBAmountUSD)
      .add(stakedDOB)
      .add(hodlPoolReward)

    return {
      total: total.toFixed(),
      balanceUSD: dobUSD.toFixed(),
      lockedUSD: stakedDOB.toFixed(),
      hodlPoolRewardUSD: hodlPoolReward.toFixed(),
    }
  })
  const totalUHODLUSD = computed(() => {
    let total = Big(0)

    // balance
    const uHODLBalance = getTokenBalanceFromWallet(
      wallet,
      BASE_TOKEN_SYMBOL.U_HODL,
    )
    const uHODLPrice = getTokenPrice(BASE_TOKEN_SYMBOL.U_HODL).value
    const uHODLUSD = Big(
      divDecimals(uHODLBalance ?? '0', BASE_TOKEN_SYMBOL.U_HODL).value,
    ).times(uHODLPrice)

    // add unclaimed uHODL reward
    const unclaimedReward = Big(
      divDecimals(userInfo.value.uHODLReward ?? '0', BASE_TOKEN_SYMBOL.U_HODL)
        .value,
    ).mul(uHODLPrice ?? '0')

    // add Staked uHODL
    const stakedUHODL = userHODLPools.value.reduce((sum, current) => {
      return sum.add(
        current.type === BASE_TOKEN_SYMBOL.U_HODL
          ? Big(
              divDecimals(current.staked, BASE_TOKEN_SYMBOL.U_HODL).value,
            ).mul(uHODLPrice ?? '0')
          : '0',
      )
    }, Big(0))

    total = total.add(uHODLUSD).add(unclaimedReward).add(stakedUHODL)
    return {
      total: total.toFixed(),
      balanceUSD: uHODLUSD.toFixed(),
      stakedUSD: stakedUHODL.toFixed(),
      unclaimedRewardUSD: unclaimedReward.toFixed(),
    }
  })
  const totalSellTokenHODLUSD = computed(() => {
    let total = Big(0)

    // balance
    const sellTokenHODLBalance = getTokenBalanceFromWallet(
      wallet,
      BASE_TOKEN_SYMBOL.SELL_TOKEN_HODL,
    )
    const sellTokenHODLPrice = getTokenPrice(
      BASE_TOKEN_SYMBOL.SELL_TOKEN_HODL,
    ).value
    const sellTokenHODLUSD = Big(
      divDecimals(
        sellTokenHODLBalance ?? '0',
        BASE_TOKEN_SYMBOL.SELL_TOKEN_HODL,
      ).value,
    ).times(sellTokenHODLPrice)

    // add unclaimed uHODL reward
    const unclaimedReward = Big(
      divDecimals(
        userInfo.value.sellTokenHODLReward ?? '0',
        BASE_TOKEN_SYMBOL.SELL_TOKEN_HODL,
      ).value,
    ).mul(sellTokenHODLPrice ?? '0')

    // add Staked sellTokenHODL
    const stakedSellTokenHODL = userHODLPools.value.reduce((sum, current) => {
      return sum.add(
        current.type === BASE_TOKEN_SYMBOL.SELL_TOKEN_HODL
          ? Big(
              divDecimals(current.staked, BASE_TOKEN_SYMBOL.SELL_TOKEN_HODL)
                .value,
            ).mul(sellTokenHODLPrice ?? '0')
          : '0',
      )
    }, Big(0))

    total = total
      .add(sellTokenHODLUSD)
      .add(unclaimedReward)
      .add(stakedSellTokenHODL)

    return {
      total: total.toFixed(),
      balanceUSD: sellTokenHODLUSD.toFixed(),
      unclaimedRewardUSD: unclaimedReward.toFixed(),
      stakedUSD: stakedSellTokenHODL.toFixed(),
    }
  })

  /**
   * @description: Net asset value of a wallet: uHODL + bHODL(eHODL) + DOB(eDOB) + staked sniper amount (USD) + unclaimed DOB reward (USD)
   * @return {string} net asset value
   */
  const total = computed(() => {
    let totalValue = Big(0)

    totalValue = totalValue.add(totalSniperUSD.value.total)
    totalValue = totalValue.add(totalDOBUSD.value.total)
    totalValue = totalValue.add(totalUHODLUSD.value.total)
    totalValue = totalValue.add(totalSellTokenHODLUSD.value.total)

    return totalValue.toFixed()
  })

  return {
    data,
    loading,
    total,
    totalSellTokenHODLUSD,
    totalUHODLUSD,
    totalDOBUSD,
    totalSniperUSD,
    totalSniperTokenAmount,
  }
}
